import { useMemo } from 'react';

import { QueryStatus } from '@tanstack/react-query';

import {
  PatientList,
  PatientListItem,
  TelemonitoringTag,
} from '@/models/PatientSummaryModel.ts';
import { Queries } from '@/queries/Queries.ts';

export type PatientListData = {
  monitored: PatientList;
  gestational: PatientList;
  all: PatientList;
};

export type UsePatientsListDataReturn = PatientListData & {
  status: QueryStatus;
};

/**
 * Get patients list data
 * Filter based on search string
 * Split patients by category
 * @param search
 */
export const usePatientsListData = (
  search: string,
): UsePatientsListDataReturn => {
  const patientList = Queries.practitioner.usePatientsList({});
  const patientListData = useMemo(() => {
    const filtered = basicSearch(search, patientList.data ?? []);
    const sorted = filtered.sort(tagSort);
    const split = splitPatientsByCategory(sorted);
    return split;
  }, [search, patientList.data]);
  return { ...patientListData, status: patientList.status };
};

/**
 * Basic filter by search term
 * Search by patient name, diabetes type and tags
 * @param search
 * @param patients
 */
const basicSearch = (search: string, patients: PatientList) => {
  if (!search || !patients) {
    return patients;
  }
  return patients.filter(patient =>
    [
      // Search by patient name (family name duplicated to search in both name orders)
      patient.familyName.toLowerCase(),
      patient.givenName.toLowerCase(),
      patient.familyName.toLowerCase(),
      // Search diabetes type
      patient.diabetesType?.toLowerCase(),
      // Search by tags
      patient.tags.join(' ').toLowerCase(),
    ]
      .join(' ')
      .includes(search.toLowerCase()),
  );
};

/**
 * Split patients by category
 * - Monitored: "active" | "expired_recently" tag
 * - Gestational: "active" | "expired_recently" tag + "gestational" diabetes type
 * - All: All patients
 * @param patients
 */
const splitPatientsByCategory = (patients: PatientList): PatientListData => {
  return patients.reduce(
    (acc, patient) => {
      if (
        patient.tags.includes('active') ||
        patient.tags.includes('expired_recently')
      ) {
        acc.monitored.push(patient);
      }
      if (
        ((patient.tags.includes('active') ||
          patient.tags.includes('expired_recently')) &&
          patient.diabetesType === 'gestational') ||
        (patient.tags.includes('gestational_no_insulin') &&
          !patient.tags.includes('expired'))
      ) {
        acc.gestational.push(patient);
      }
      acc.all.push(patient);
      return acc;
    },
    {
      monitored: [],
      gestational: [],
      all: [],
    } as PatientListData,
  );
};

/**
 * Current table sort logic
 * Sort order:
 * - 1st: "expired_recently"
 * - 2nd: "expiring_soon"
 * - 3rd: not "no_glycemia_data" (onboarding is complete)
 * - 4th: "no_glycemia_data" (installation is ongoing)
 * - 5th: "expired"
 * - When tags are the same, sort by family name
 */
const tagSort = (a: PatientListItem, b: PatientListItem) => {
  // Expired recently goes first
  // Expiring soon goes second
  // Active goes third
  // No glycemia data goes fourth
  // Expired goes fifth
  const sort = compareByTags(
    ['expired', 'expired_recently'],
    ['active', 'expiring_soon'],
    ['active'],
    ['active', 'no_glycemia_data'],
    ['expired'],
  )(a, b);

  if (sort !== 0) {
    return sort;
  }

  return a.familyName.localeCompare(b.familyName);
};

/**
 * Compare patients by tags priority
 * Give each tag list a score based on how many tags match the patient
 * Sort by the highest score
 * Example:
 * ```
 * const sorted = patients.sort(
 *  compareByTags(['active'], ['expired'])
 * );
 * ```
 * Will sort patients by active first then expired
 *
 * @param priority A list of tags from highest to lowest priority
 * @returns A function to compare two patients
 */
const compareByTags =
  (...priority: TelemonitoringTag[][]) =>
  (a: PatientListItem, b: PatientListItem) => {
    if (priority.length === 0) {
      return 0;
    }
    const aMatch = priority
      .map((prio, i) => [matchScore(a, prio), i])
      .sort((a, b) => b[0] - a[0])[0][1];
    const bMatch = priority
      .map((prio, i) => [matchScore(b, prio), i])
      .sort((a, b) => b[0] - a[0])[0][1];

    return aMatch - bMatch;
  };

/**
 * Give a score to a patient based on how many of their telemonitoring tags
 * match the list of tags
 * @param patient
 * @param tags
 */
const matchScore = (patient: PatientListItem, tags: TelemonitoringTag[]) => {
  // If the patient has a tag, give them a point else remove a point
  const score = patient.tags.reduce(
    (acc, tag) => (tags.includes(tag) ? acc + 1 : acc - 1),
    0,
  );
  // If the patient is missing a tag, remove a point
  return tags.reduce(
    (acc, tag) => (patient.tags.includes(tag) ? acc : acc - 1),
    score,
  );
};
