import { QueryClient } from '@tanstack/react-query';

import { ApiErrorResponse } from '@/models/ApiErrorResponse';
import { AlertsQueries } from '@/queries/AlertsQueries';
import { ChatQueries } from '@/queries/ChatQueries';
import { ConditionQueries } from '@/queries/ConditionQueries';
import { DiabetesQueries } from '@/queries/DiabetesQueries';
import { InsulinQueries } from '@/queries/InsulinQueries.ts';
import { MailPreferenceQueries } from '@/queries/MailPreferenceQueries';
import { ObservationsQueries } from '@/queries/ObservationsQueries';
import { PatientQueries } from '@/queries/PatientQueries';
import { PractitionerQueries } from '@/queries/PractitionerQueries';
import { PrescriptionQueries } from '@/queries/PrescriptionQueries.ts';
import { UserQueries } from '@/queries/UserQueries';
import { Result } from '@/utils/Result';

import { QaQueries } from './QaQueries';

////////////////////////////////////////
////             Queries            ////
////////////////////////////////////////

/**
 * Find the status code from an error
 * @param error
 */
const getStatusCodeFromError = (error: unknown): number | undefined => {
  return error &&
    typeof error === 'object' &&
    'statusCode' in error &&
    typeof error.statusCode === 'number'
    ? error.statusCode
    : undefined;
};

/**
 * Default retry function
 * @param failureCount
 * @param error
 */
export const defaultRetryFn = (
  failureCount: number,
  error: unknown,
): boolean => {
  const status = getStatusCodeFromError(error);
  switch (status) {
    case 500:
      return failureCount < 10;
    default:
      return false;
  }
};

/**
 * Default retry delay function
 * Delay:
 * - 2 seconds for the first failure
 * - 4 seconds for the second failure
 * - 8 seconds for the third failure
 * - 16 seconds for the fourth failure
 * - 32 seconds for the fifth failure
 * - 1 minute for the sixth failure
 * - 2 minutes for the seventh failure
 * - 4 minutes for the eighth failure
 * - 5 minutes for all subsequent failures
 * @param failureCount
 * @param error
 */
export const defaultRetryDelayFn = (
  failureCount: number,
  error: unknown,
): number => {
  const status = getStatusCodeFromError(error);
  switch (status) {
    case 500:
      return Math.min(2000 * 2 ** failureCount, 300_000);
    default:
      return 60_000;
  }
};

/**
 * Transform an API result into a value, or throw an error
 * @param result
 */
export const stripQueryResult = <T>(result: Result<T, ApiErrorResponse>): T => {
  switch (result.status) {
    case 'Ok':
      return result.data;
    case 'Err':
      throw result.error;
  }
};

/**
 * Creates a query key with the given keys
 * Removes undefined keys
 * Allows the simple creation of query keys with optional keys
 * For example invalidating all caches of a query can just be done with makeQueryKey()
 * @param keys
 */
export const makeQueryKey = (...keys: (string | undefined)[]): string[] => {
  return keys.filter(key => !!key) as string[];
};

////////////////////////////////////////
////           Query Client         ////
////////////////////////////////////////

/**
 * Default query client
 * Should NEVER be used directly
 * Use the queryClient from the context instead
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: defaultRetryFn,
      retryDelay: defaultRetryDelayFn,
      staleTime: 300_000, // 5 minutes
      gcTime: Infinity, // Never garbage collect
    },
  },
});

////////////////////////////////////////
////             Helpers            ////
////////////////////////////////////////

const practitioner = new PractitionerQueries();

/**
 * Singleton class that contains all queries
 */
export const Queries = {
  alerts: new AlertsQueries(practitioner),
  observations: new ObservationsQueries(practitioner),
  patient: new PatientQueries(),
  practitioner,
  user: new UserQueries(),
  diabetes: new DiabetesQueries(),
  insulin: new InsulinQueries(),
  chat: new ChatQueries(),
  prescription: new PrescriptionQueries(),
  mailPreference: new MailPreferenceQueries(),
  condition: new ConditionQueries(),
  qa: new QaQueries(),
};
