import { stringUnion } from '@/utils/decoderUtils';

export type Profession = 'doctor' | 'nurse';

export const professionsList: Profession[] = ['doctor', 'nurse'];

export const professionDecoder = stringUnion<Profession>(...professionsList);

export const ProfessionsTranslations = {
  doctor: 'profile.doctor',
  nurse: 'profile.nurse',
} as const satisfies Record<Profession, string>;
