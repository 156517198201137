import React from 'react';

import { css } from '@emotion/css';
import { Typography } from '@mui/material';
import { CellContext } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles.ts';
import {
  DiabetesType,
  diabetesTypeColorMapping,
  diabetesTypeTranslationMapping,
} from '@/models/CarePlanModel.ts';
import { PatientListItem } from '@/models/PatientSummaryModel.ts';
import { Tag } from '@/uiKit/tag/Tag.tsx';

export type DiabetesTypeColumnProps = CellContext<
  PatientListItem,
  DiabetesType
>;

export const DiabetesTypeColumn: React.FC<DiabetesTypeColumnProps> = ({
  row,
}) => {
  const { t } = useTranslation();
  const type = row.original.diabetesType;
  const insulin = row.original.insulinTreatmentType;
  const styles = useStyles(makeStyles);

  return (
    <div className={styles.container}>
      <Tag
        label={t(diabetesTypeTranslationMapping(type, insulin))}
        color={diabetesTypeColorMapping(type, insulin)}
      />
    </div>
  );
};

export const DiabetesTypeHeader: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles(makeHeaderStyles);
  return (
    <div className={styles.container}>
      <Typography>{t('pages.patients.diabetesType')}</Typography>
    </div>
  );
};

const makeStyles = () => ({
  container: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `,
});

const makeHeaderStyles = () => ({
  container: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `,
});
