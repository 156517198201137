import { DecoderFunction } from 'typescript-json-decoder';

import { PatientListMenuItems } from '@/pages/patients/patients-list/PatientListMenu.tsx';
import {
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils.ts';

export type PatientListUISettings = {
  selectedFilter?: PatientListMenuItems;
};

export const patientListUISettingsDecoder: DecoderFunction<PatientListUISettings> =
  recordWithContext('PatientListUISettings', {
    selectedFilter: nullOrUndef(stringUnion('monitored', 'gestational', 'all')),
  });

export type BGMViewKind = 'downIsFuture' | 'downIsPast';

export type BGMPreferences = {
  viewKind?: BGMViewKind;
};

export const bgmPreferencesDecoder: DecoderFunction<BGMPreferences> =
  recordWithContext('BGMPreferences', {
    viewKind: nullOrUndef(stringUnion('downIsFuture', 'downIsPast')),
  });
