/**
 * TargetDurationStatsModel
 *
 * Partial object of the global stats
 * TODO find out what the real object looks like
 */
import { BaseGlycemiaParameters } from '@/models/DiabetesDataModel';

export type GlycemiaDistributionRange = {
  glycemia_range: string;
  ratio: number;
};

type TargetDurationStatsCommon = {
  high_glycemia: number;
  target_glycemia: number;
  low_glycemia: number;
  parameters: BaseGlycemiaParameters;
  glycemia_distribution: GlycemiaDistributionRange[];
};

export type TargetDurationStats = {
  reader: TargetDurationStatsCommon & {
    total_glycemia: number;
  };
  sensor: TargetDurationStatsCommon & {
    total_time: number;
  };
};

export type TargetDurationStatsKeys = keyof TargetDurationStats;
export type TargetDurationStatsValues =
  TargetDurationStats[TargetDurationStatsKeys];

export const targetDurationStatsDecoder = (
  value: unknown,
): TargetDurationStats => value as TargetDurationStats;
