import React from 'react';

import { scaleLinear } from '@visx/scale';

import { AGPStats } from '@/models/AGPStatsModel';
import { CGMGlycemiaParameters } from '@/models/DiabetesDataModel';
import { AGPCurves } from '@/uiKit/molecules/graphs/AgpProfile/AGPCurves';
import { Template } from '@/uiKit/molecules/graphs/AgpProfile/Template';
import * as GRAPH from '@/uiKit/molecules/graphs/AgpProfile/constants';

const splitData = (data: AGPStats = {}) => {
  if (!data || Object.keys(data).length == 0) {
    return [];
  }
  const split: AGPStats[] = [];
  let split_curr: AGPStats = {};
  let last_hour = -1;
  Object.entries(data).forEach(([key, value]) => {
    if (key - last_hour === 1) {
      split_curr[key] = value;
    } else {
      if (Object.keys(split_curr).length > 0) split.push(split_curr); // > 1 only group with multiple values ?
      split_curr = { [key]: value };
    }
    last_hour = key;
  });
  if (Object.keys(split_curr).length > 0) split.push(split_curr);
  return split;
};

type AgpProfileSvgProps = {
  agp: AGPStats;
  thresholds: CGMGlycemiaParameters;
  height?: number;
  width: number;
};

export const AgpProfileSvg: React.FC<AgpProfileSvgProps> = ({
  agp,
  thresholds,
  height = 240,
  width,
}) => {
  const hasValidDimension = height > 0 && width > 0;
  const maxY = Math.round(
    Math.max(...Object.values(agp).map(percentile => percentile['95'])),
  );

  const scales = {
    rescaleY: scaleLinear({
      domain: [maxY, 0],
      range: [
        GRAPH.margin.top,
        GRAPH.margin.top + (height - GRAPH.margin.top - GRAPH.margin.bottom),
      ],
    }),
    rescaleX: scaleLinear({
      domain: [0, GRAPH.HOURS_SCALING],
      range: [
        GRAPH.margin.left,
        width - GRAPH.margin.right + GRAPH.margin.left,
      ],
    }),
  };

  const lastDataKey = Math.max(...Object.keys(agp));
  const lastData = agp[lastDataKey];

  const [agpSplitted, setAgpSplitted] = React.useState<AGPStats[]>([]);

  React.useEffect(() => {
    if (agp) setAgpSplitted(splitData(agp));
  }, [agp]);

  return (
    <svg width={width} height={height}>
      {hasValidDimension && (
        <Template
          thresholds={thresholds}
          parentWidth={width}
          parentHeight={height}
          scales={scales}
          maxY={maxY}
          lastData={lastData}
        >
          {agpSplitted.map((agp, i) => (
            <AGPCurves key={i} agp={agp} scales={scales} />
          ))}
        </Template>
      )}
    </svg>
  );
};
