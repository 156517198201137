import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles.ts';
import { PageContainer } from '@/pages/PageContainer';
import { AboutSteto } from '@/pages/account/AboutSteto';
import { GeneralInformation } from '@/pages/account/GeneralInformation';
import { Language } from '@/pages/account/Language.tsx';
import { Security } from '@/pages/account/Security';
import { Terms } from '@/pages/account/Terms';
import { UserHelp } from '@/pages/account/UserHelp';
import { MailPreference } from '@/pages/account/preferences/MailPreference';
import { HealthProLayout } from '@/pages/layout/HealthProLayout';

export type PractitionerAccountPageProps = {
  // Empty
};

export const PractitionerAccountPage: React.FC<
  PractitionerAccountPageProps
> = ({}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  return (
    <HealthProLayout>
      <PageContainer
        title={t('pages.account.title')}
        className={styles.content}
      >
        <GeneralInformation />
        <MailPreference />
        <div className={styles.row}>
          <Language className={css({ flex: 1 })} />
          <Security className={css({ flex: 1 })} />
        </div>
        <UserHelp isPractitioner />
        <Terms />
        <AboutSteto />
      </PageContainer>
    </HealthProLayout>
  );
};

const makeStyles = (theme: Theme) => ({
  content: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(12)};
  `,
  row: css`
    display: flex;
    gap: ${theme.spacing(12)};
  `,
});
