import React from 'react';

import { css } from '@emotion/css';
import { TableCell, TableRow, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons.ts';
import { useStyles } from '@/hooks/useStyles';

export type BGMLoadMoreProps = {
  loadMore: () => void;
};

export const BGMLoadMore: React.FC<BGMLoadMoreProps> = ({ loadMore }) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  return (
    <TableRow className={styles.container} onClick={loadMore}>
      <TableCell colSpan={100}>
        <div className={styles.cell}>
          <Icons.plus className={styles.icon} />
          <Typography variant="body">{t('bgmLogBook.loadMore')}</Typography>
        </div>
      </TableCell>
    </TableRow>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    background-color: ${theme.palette.bgm.darkColumn};
    user-select: none;
    cursor: pointer;
    :hover {
      background-color: ${theme.palette.bgm.clearColumn};
    }
  `,
  cell: css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  icon: css`
    margin-right: ${theme.spacing(2)};
    width: ${theme.typography.body.fontSize};
    height: ${theme.typography.body.fontSize};
  `,
});
