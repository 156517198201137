import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource';
import {
  LocalStorageDataSourceSpec,
  localStorageDataSource,
} from '@/io/datasource/LocalStorageDatasource.ts';
import {
  SessionStorageDataSourceSpec,
  sessionStorageDataSource,
} from '@/io/datasource/SessionStorageDatasource.ts';
import { ApiErrorResponse } from '@/models/ApiErrorResponse';
import {
  BGMPreferences,
  PatientListUISettings,
  bgmPreferencesDecoder,
  patientListUISettingsDecoder,
} from '@/models/UISettingsModel.ts';
import { ExtendedUser, extendedUserDecoder } from '@/models/UserModel';
import { Result } from '@/utils/Result';

export class UserRepository {
  constructor(
    private readonly datasource: ApiDataSourceSpec = apiDataSource,
    private readonly localDatasource: LocalStorageDataSourceSpec = localStorageDataSource,
    private readonly sessionDatasource: SessionStorageDataSourceSpec = sessionStorageDataSource,
  ) {}

  getUser = (): Promise<Result<ExtendedUser, ApiErrorResponse>> => {
    return this.datasource.get('practitioner/me/', extendedUserDecoder);
  };

  getFeatureFlags = (): Promise<
    Result<Record<string, boolean>, ApiErrorResponse>
  > => {
    return this.datasource.get(
      '/feature-flags/',
      data => data as Record<string, boolean>,
    );
  };

  getBGMPreferences = async (userId: string): Promise<BGMPreferences> => {
    const result = await this.localDatasource.retrieve(
      userId,
      'BGMPreferences',
      bgmPreferencesDecoder,
    );
    return result ?? {};
  };

  updateBGMPreferences = async (userId: string, data: BGMPreferences) => {
    await this.localDatasource.store(userId, 'BGMPreferences', data);
  };

  getPatientListUISettings = async (
    userId: string,
  ): Promise<PatientListUISettings> => {
    const result = await this.sessionDatasource.retrieve(
      userId,
      'patientList',
      patientListUISettingsDecoder,
    );
    return result ?? {};
  };

  updatePatientListUISettings = async (
    userId: string,
    data: PatientListUISettings,
  ) => {
    await this.sessionDatasource.store(userId, 'patientList', data);
  };
}
