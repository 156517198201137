import {
  DecoderFunction,
  number,
  record,
  string,
} from 'typescript-json-decoder';

import { Gender } from '@/models/GenderModel';
import { Profession } from '@/models/ProfessionModel';
import { intersectionWithContext, nullOrUndef } from '@/utils/decoderUtils';

import {
  FrenchIdentity,
  frenchIdentityDecoder,
} from './identity/FrenchIdentity';

/*********************************
 *            Patient            *
 *********************************/

export type PatientID = {
  id: string;
};

export const patientIDDecoder: DecoderFunction<PatientID> = record({
  id: string,
});

export type MessageCountType = {
  sent_by_patient: number;
  sent_by_practitioner_to_all: number;
  sent_by_practitioner_to_practitioner: number;
  sent_by_dg_patient: number;
};

export const messageCountDecoder: DecoderFunction<MessageCountType> =
  intersectionWithContext('MessageCount', {
    sent_by_patient: number,
    sent_by_practitioner_to_all: number,
    sent_by_practitioner_to_practitioner: number,
    sent_by_dg_patient: number,
  });

export type Patient = {
  id: string;
  givenName: string;
  familyName: string;
  contactPhone: string;
  email: string;
  addressLine?: string;
  addressPostalCode?: string;
  addressCity?: string;
  weight?: string;
  last_login?: string;
} & FrenchIdentity;

export const patientDecoder: DecoderFunction<Patient> = intersectionWithContext(
  'Patient',
  record({
    id: string,
    givenName: string,
    familyName: string,
    contactPhone: string,
    weight: nullOrUndef(value =>
      typeof value === 'number' ? String(value) : (value as string),
    ),
    email: string,
    addressLine: nullOrUndef(string),
    addressPostalCode: nullOrUndef(string),
    addressCity: nullOrUndef(string),
    last_login: nullOrUndef(string),
  }),
  frenchIdentityDecoder,
);

export type UpdatePatient = {
  givenName?: string;
  familyName?: string;
  contactPhone?: string;
  addressLine?: string;
  addressCity?: string;
  addressPostalCode?: string;
  addressCountry?: string;
  gender?: Gender;
  birthDate?: string;
  qualification?: Profession;
};
