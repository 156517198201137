import { PropsWithChildren, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Button, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { RenewalSuggestion } from '@/models/CarePlanModel';

import { CarePlanRenewal } from '../patient-settings/CarePlanRenewal';

type PatientTableRenewButtonProps = PropsWithChildren<{
  patientId: string;
  renewalSuggestion?: RenewalSuggestion;
  isButtonVisible: boolean;
  className?: string;
}>;

export const PatientTableRenewButton = ({
  children,
  patientId,
  renewalSuggestion,
  isButtonVisible,
  className,
}: PatientTableRenewButtonProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const styles = useStyles(makeStyle, isButtonVisible);
  const buttonLabel =
    renewalSuggestion == 'expiring'
      ? t('prescription.renew')
      : renewalSuggestion == 'add_insulin'
        ? t('diabetesForms.addInsulin')
        : null;

  return (
    <>
      <div className={cx(styles.container, className)}>
        <Button
          size="small"
          variant="contained"
          onClick={e => {
            e.stopPropagation(); // Needed otherwise the navigation of the table will trigger
            setIsModalOpen(true);
          }}
          className={styles.tableButton}
        >
          {buttonLabel}
        </Button>
        {isButtonVisible ? null : children}
      </div>
      {isModalOpen ? (
        <CarePlanRenewal
          patientId={patientId}
          onClose={() => setIsModalOpen(false)}
        />
      ) : null}
    </>
  );
};

const makeStyle = (_theme: Theme, isButtonVisible: boolean) => ({
  container: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  tableButton: css({
    width: isButtonVisible ? undefined : '0 !important',
    minWidth: '0 !important',
    paddingLeft: isButtonVisible ? undefined : '0 !important',
    paddingRight: isButtonVisible ? undefined : '0 !important',
    height: isButtonVisible ? undefined : '0 !important',
    overflow: 'clip',
    textWrap: 'nowrap',
  }),
});
