import { useState } from 'react';

import { css } from '@emotion/css';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Radio,
  Theme,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { Queries } from '@/queries/Queries';
import { downloadCSVFromBlob } from '@/utils/downloadFileFromBlob';

type GlycemiaDataManagementProps = {};

export const GlycemiaDataManagement = ({}: GlycemiaDataManagementProps) => {
  const patientId = usePatientIdFromURL() ?? '';
  const downloadCSV = Queries.qa.useExportGlycemiaCSV();
  const generateGlycemiaData = Queries.qa.useGenerateGlycemiaData();
  const deleteGlycemiaData = Queries.qa.useDeleteGlycemiaData();
  const uploadGlycemiaCSV = Queries.qa.useUploadGlycemiaCSV();

  const [selectedDiabetesType, setSelectedDiabetesType] = useState('CGM');
  const [csvToUpload, setCsvToUpload] = useState<File>();

  const styles = useStyles(makeStyles);

  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader title={t('QaTools.glycemiaDataManagement')} />
      <CardContent className={styles.space}>
        <div className={styles.menuItem}>
          <Typography variant="subtitle">
            {t('QaTools.downloadCSVTitle')}
          </Typography>
          <Button
            variant="contained"
            onClick={() =>
              downloadCSV.mutate(
                {
                  patientId,
                },
                {
                  onSuccess: downloadCSVFromBlob,
                },
              )
            }
          >
            {t('QaTools.downloadCSV')}
          </Button>
        </div>
        <div className={styles.menuItem}>
          <Typography variant="subtitle">
            {t('QaTools.uploadCSVTitle')}
          </Typography>
          <input
            type="file"
            hidden
            onChange={event => {
              const file = event.target.files?.[0];
              if (file) {
                setCsvToUpload(file);
              }
            }}
          />
          <Button
            onClick={() =>
              csvToUpload &&
              uploadGlycemiaCSV.mutate({ patientId, file: csvToUpload })
            }
            variant="contained"
            disabled={!csvToUpload}
          >
            {t('QaTools.uploadCSV')}
          </Button>
        </div>
        <div className={styles.menuItem}>
          <Typography variant="subtitle">
            {t('QaTools.generateDataTitle')}
          </Typography>
          <FormControlLabel
            control={
              <Radio
                checked={selectedDiabetesType === 'BGM'}
                onChange={() => setSelectedDiabetesType('BGM')}
                value="BGM"
              />
            }
            label="BGM"
          />
          <FormControlLabel
            control={
              <Radio
                checked={selectedDiabetesType === 'CGM'}
                onChange={() => setSelectedDiabetesType('CGM')}
                value="CGM"
              />
            }
            label="CGM"
          />
          <Button
            variant="contained"
            onClick={() => {
              generateGlycemiaData.mutate({
                patientId,
                glycemiaType: selectedDiabetesType,
              });
            }}
          >
            {t('QaTools.generateData')}
          </Button>
        </div>
        <div className={styles.menuItem}>
          <Typography variant="subtitle">
            {t('QaTools.deleteDataTitle')}
          </Typography>
          <Button
            onClick={() => deleteGlycemiaData.mutate({ patientId })}
            variant="contained"
          >
            {t('QaTools.deleteData')}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  menuItem: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  space: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(12)};
  `,
});
