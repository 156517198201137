import React from 'react';

import { css } from '@emotion/css';
import { InputProps, Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';
import {
  BaseGlycemiaParameters,
  GlycemiaParametersType,
} from '@/models/DiabetesDataModel';
import { ThresholdStep } from '@/uiKit/molecules/thresholds/ThresholdStep.tsx';

import { ThresholdInput } from './ThresholdInput';

/**
 * List of inputs linked to their respective thresholds.
 * They are offset for better UX
 * For hyper, the input is below the threshold
 * For hypo, the input is above the threshold
 */
export type ThresholdInputMapping = {
  [key in ThresholdStep]: GlycemiaParametersType | undefined;
};

export type ThresholdStepListProps = {
  diabetesParameters: BaseGlycemiaParameters;
  thresholds: ThresholdStep[];
  thresholdInputMapping: ThresholdInputMapping;
  onSubmit: (threshold: GlycemiaParametersType, value: number) => void;
  inputProps?: (threshold: ThresholdStep) => InputProps;
};

export const ThresholdStepList: React.FC<ThresholdStepListProps> = ({
  diabetesParameters,
  thresholds,
  thresholdInputMapping,
  onSubmit,
  inputProps,
}) => {
  const styles = useStyles(makeStyles);

  return (
    <ul className={styles.container}>
      {thresholds.map(threshold => {
        const inputThreshold = thresholdInputMapping[threshold];
        return (
          <ThresholdStep
            key={threshold}
            threshold={threshold}
            className={styles.step}
          >
            {inputThreshold ? (
              <div className={styles.inputContainer}>
                <ThresholdInput
                  diabetesParameters={diabetesParameters}
                  threshold={inputThreshold}
                  onSubmit={value => onSubmit(inputThreshold, value)}
                  inputProps={inputProps ? inputProps(threshold) : undefined}
                />
              </div>
            ) : null}
          </ThresholdStep>
        );
      })}
    </ul>
  );
};

/**
 * List of thresholds to be displayed in the ThresholdSettings component.
 */
export const allThresholds: ThresholdStep[] = [
  'severeHyperglycemia',
  'hyperglycemia',
  'normal',
  'hypoglycemia',
  'severeHypoglycemia',
] as const;

export const allThresholdInputMapping: ThresholdInputMapping = {
  severeHyperglycemia: 'severeHyperglycemia',
  hyperglycemia: 'hyperglycemia',
  normal: 'hypoglycemia',
  hypoglycemia: 'severeHypoglycemia',
  severeHypoglycemia: undefined,
};

export const gestationalThresholds: ThresholdStep[] = [
  'severeHyperglycemia',
  'hyperglycemia',
  'normal',
  'hypoglycemia',
] as const;

export const gestationalThresholdInputMapping: ThresholdInputMapping = {
  severeHyperglycemia: 'severeHyperglycemia',
  hyperglycemia: 'hyperglycemia',
  normal: 'hypoglycemia',
  hypoglycemia: undefined,
  severeHypoglycemia: undefined,
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,
  step: css`
    position: relative;
    display: flex;
    justify-content: center;
  `,
  inputContainer: css`
    position: absolute;
    height: 2.5rem;
    bottom: -1.25rem;
    background-color: white;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-radius: ${theme.spacing(2)};
    padding: ${theme.spacing(0, 8)};
  `,
});
