import {
  DecoderFunction,
  field,
  number,
  record,
  string,
} from 'typescript-json-decoder';

import {
  intersectionWithContext,
  nullOrUndef,
  recordWithContext,
  stringUnion,
  withDefaultValue,
} from '@/utils/decoderUtils';

export type BaseDiabetesDataModel = {
  id: number;
  patientId: string;
  date: string;
};

export const baseDiabetesDataModelDecoder: DecoderFunction<BaseDiabetesDataModel> =
  recordWithContext('BaseDiabetesDataModel', {
    id: number,
    patientId: field('patient_id', string),
    date: string,
  });

export type FoodSize = 'unknown' | 'light' | 'big' | 'medium';

export type Food = {
  size: FoodSize;
  carbs?: number;
  comments?: string;
} & BaseDiabetesDataModel;

export type ActivityIntensity = 'low' | 'medium' | 'high';

export type Activity = {
  intensity: ActivityIntensity;
  duration: number;
} & BaseDiabetesDataModel;

export type ReportCategory =
  | 'severe_hypoglycemia'
  | 'perceived_hypoglycemia'
  | 'perceived_hyperglycemia'
  | 'tech_alert'
  | 'other';

export type Report = {
  category: ReportCategory;
  message: string;
} & BaseDiabetesDataModel;

export const foodDecoder: DecoderFunction<Food> = intersectionWithContext(
  'Food',
  baseDiabetesDataModelDecoder,
  record({
    size: stringUnion<FoodSize>('unknown', 'light', 'big', 'medium'),
    carbs: nullOrUndef(number),
    comments: nullOrUndef(string),
  }),
);

export const activityDecoder: DecoderFunction<Activity> =
  intersectionWithContext(
    'Activity',
    baseDiabetesDataModelDecoder,
    record({
      intensity: stringUnion<ActivityIntensity>('low', 'medium', 'high'),
      duration: number,
    }),
  );

export const reportDecoder: DecoderFunction<Report> = intersectionWithContext(
  'Report',
  baseDiabetesDataModelDecoder,
  record({
    category: stringUnion<ReportCategory>(
      'severe_hypoglycemia',
      'perceived_hypoglycemia',
      'perceived_hyperglycemia',
      'tech_alert',
      'other',
    ),
    message: string,
  }),
);

export type GlycemiaParametersType =
  | 'hypoglycemia'
  | 'hyperglycemia'
  | 'severeHypoglycemia'
  | 'severeHyperglycemia';

export type BaseGlycemiaParameters = {
  [K in GlycemiaParametersType]: number;
};

export type BGMGlycemiaParametersType = 'fasting' | 'beforeMeal' | 'afterMeal';

/**
 * ```
 *  {
 *    id: '123',
 *    hypoglycemia: 70,
 *    hyperglycemia: 180,
 *    severeHypoglycemia: 50,
 *    severeHyperglycemia: 300,
 *  }
 */
export type CGMGlycemiaParameters = {
  id: string;
} & BaseGlycemiaParameters;

/**
 * ```
 *  {
 *    id: '123',
 *    fasting: {
 *     severeHyperglycemia: 300,
 *      hyperglycemia: 180,
 *      hypoglycemia: 70,
 *      severeHypoglycemia: 50,
 *    },
 *    beforeMeal: {
 *      severeHyperglycemia: 300,
 *      hyperglycemia: 180,
 *      hypoglycemia: 0,
 *      severeHypoglycemia: 0,
 *    },
 *    afterMeal: {
 *     severeHyperglycemia: 300,
 *      hyperglycemia: 180,
 *      hypoglycemia: 70,
 *      severeHypoglycemia: 0,
 *    },
 *  }
 *  ```
 */
export type BGMGlycemiaParameters = {
  id: string;
} & {
  [K in BGMGlycemiaParametersType]: BaseGlycemiaParameters;
};

export type GlycemiaParameters =
  | {
      type: 'CGM';
      parameters: CGMGlycemiaParameters;
    }
  | {
      type: 'BGM';
      parameters: BGMGlycemiaParameters;
    };

const glycemiaParametersDecoder: DecoderFunction<BaseGlycemiaParameters> =
  record({
    hypoglycemia: field('hypoglycemia', withDefaultValue(number, 0)),
    hyperglycemia: field('hyperglycemia', withDefaultValue(number, 0)),
    severeHypoglycemia: field(
      'severe_hypoglycemia',
      withDefaultValue(number, 0),
    ),
    severeHyperglycemia: field(
      'severe_hyperglycemia',
      withDefaultValue(number, 0),
    ),
  });

export const cgmGlycemiaParametersDecoder: DecoderFunction<CGMGlycemiaParameters> =
  intersectionWithContext(
    'CGMGlycemiaParameters',
    record({
      id: string,
    }),
    glycemiaParametersDecoder,
  );

export const bgmGlycemiaParametersDecoder: DecoderFunction<BGMGlycemiaParameters> =
  recordWithContext('BGMGlycemiaParameters', {
    id: string,
    fasting: glycemiaParametersDecoder,
    beforeMeal: field('before_meal', glycemiaParametersDecoder),
    afterMeal: field('after_meal', glycemiaParametersDecoder),
  });

export type EditGlycemiaParameters = {
  hypoglycemia?: number;
  hyperglycemia?: number;
  severe_hypoglycemia?: number;
  severe_hyperglycemia?: number;
};

export type EditCGMGlycemiaParameters = {
  id: string;
} & EditGlycemiaParameters;

export type EditBGMGlycemiaParameters = {
  id: string;
  fasting?: EditGlycemiaParameters;
  before_meal?: EditGlycemiaParameters;
  after_meal?: EditGlycemiaParameters;
};
