import React, { useEffect } from 'react';

import { css } from '@emotion/css';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Skeleton,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons.ts';
import { Loader } from '@/components/loading/Loader.tsx';
import { useBGMContent } from '@/hooks/useBGMContent.ts';
import { isScrollNearBottom, useScroll } from '@/hooks/useScroll.ts';
import { useStyles } from '@/hooks/useStyles.ts';
import { Patient } from '@/models/PatientModel.ts';
import { PractitionerUser } from '@/models/UserModel.ts';
import { BGMContainerBody } from '@/uiKit/organisms/BGMLogBook/BGMContainerBody.tsx';

export type BGMTableCardProps = {
  practitioner: PractitionerUser;
  patient: Patient;
  date: DateTime;
};

const MemoizedBGMBody = React.memo(BGMContainerBody);

export const BGMTableCard: React.FC<BGMTableCardProps> = ({
  practitioner,
  patient,
  date,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  const { data, loadMoreData, kind, setKind, status } = useBGMContent(
    practitioner,
    patient,
    date,
  );
  const scroll = useScroll();

  useEffect(() => {
    if (isScrollNearBottom(scroll) && kind === 'downIsPast') {
      loadMoreData();
    }
  });

  return (
    <Card>
      <CardHeader
        title={t('bgmLogBook.cardTitle')}
        action={
          <ToggleButtonGroup
            color="primary"
            value={kind}
            exclusive
            onChange={(_, newKind) => newKind && setKind(newKind)}
            className={styles.toggleGroup}
          >
            <ToggleButton
              value="downIsPast"
              aria-label="downIsPast"
              title={t('bgmLogBook.downIsPast')}
            >
              <Icons.arrowDown />
            </ToggleButton>
            <ToggleButton
              value="downIsFuture"
              aria-label="downIsFuture"
              title={t('bgmLogBook.downIsFuture')}
            >
              <Icons.arrowUp />
            </ToggleButton>
          </ToggleButtonGroup>
        }
      />
      <CardContent>
        {status !== 'success' ? (
          <>
            <Skeleton height={53} variant="text" />
            <Skeleton height={600} variant="rounded" />
          </>
        ) : (
          <MemoizedBGMBody
            patientId={patient.id}
            data={data}
            loadMore={kind === 'downIsFuture' ? loadMoreData : undefined}
          />
        )}{' '}
      </CardContent>
      <CardActions className={styles.footer}>
        {kind === 'downIsPast' && <Loader size="L" className={styles.loader} />}
      </CardActions>
    </Card>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  footer: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: ${theme.spacing(2)};
  `,
  loader: css`
    align-self: center;
    margin: ${theme.spacing(4, 0)};
  `,
  toggleGroup: css`
    .MuiToggleButton-root {
      padding: ${theme.spacing(2)};
    }
  `,
});
