import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { useStyles } from '@/hooks/useStyles';

import { GlycemiaDataManagement } from './GlycemiaDataManagement';
import { OnboardingManagment } from './OnboardingManagment';

type QAToolsProps = {};

export const QATools = ({}: QAToolsProps) => {
  const styles = useStyles(makeStyles);

  return (
    <div className={styles.container}>
      <GlycemiaDataManagement />
      <OnboardingManagment />
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(8)};
  `,
});
