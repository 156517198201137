import { useEffect } from 'react';

import { DateTime } from 'luxon';
import { createStore } from 'zustand';

import { Periodicity } from '@/components/period-picker/PeriodPicker/PeriodSelector';
import { useQueryDateRange } from '@/hooks/query/useQueryDateRange';
import { Patient } from '@/models/PatientModel';
import { PractitionerUser } from '@/models/UserModel.ts';
import { getDateRangeAndPeriodicity } from '@/pages/patient-monitoring/PatientMonitoringPeriod';
import { makeZustandContextStore } from '@/utils/zustandUtils';

export type PatientMonitoringData = {
  practitioner: PractitionerUser;
  periodicity: Periodicity;
  from: DateTime;
  to: DateTime;
  patient: Patient;
};

export type PatientMonitoringState = PatientMonitoringData & {
  setDateRange: (data: { from: DateTime; to: DateTime }) => void;
};

const createPatientMonitoringStore = (initProps: PatientMonitoringData) => {
  return createStore<PatientMonitoringState>()(set => ({
    ...initProps,
    setDateRange: data => set(getDateRangeAndPeriodicity(data)),
  }));
};

export const [, PatientMonitoringProvider, usePatientMonitoringContext] =
  makeZustandContextStore<PatientMonitoringData, PatientMonitoringState>(
    'PatientMonitoring',
    createPatientMonitoringStore,
  );

export const useListenToQueryDateRange = () => {
  const dateRange = useQueryDateRange();
  const setDateRange = usePatientMonitoringContext(state => state.setDateRange);
  useEffect(() => {
    setDateRange(getDateRangeAndPeriodicity(dateRange));
  }, [dateRange, setDateRange]);
};
