import React from 'react';

import { css } from '@emotion/css';

import { ShowApiError } from '@/components/error/ShowApiError.tsx';
import { Col } from '@/components/layout/Flex.tsx';
import { Loader } from '@/components/loading/Loader.tsx';
import { useStyles } from '@/hooks/useStyles';
import { BGMTableCard } from '@/pages/patient-monitoring/BGMTableCard.tsx';
import { usePatientMonitoringContext } from '@/pages/patient-monitoring/PatientMonitoringState.tsx';
import { Queries } from '@/queries/Queries.ts';
import { GlycemiaProfiles } from '@/uiKit/organisms/DailyGraphList/GlycemiaProfiles';

export const MonitoringDailyData: React.FC = () => {
  const state = usePatientMonitoringContext(state => state);
  const styles = useStyles(makeStyles);

  const diabetesDataDisplayMode = Queries.practitioner.useDiabetesDisplayMode(
    state.patient.id,
  );

  switch (diabetesDataDisplayMode.status) {
    case 'error':
      return (
        <ShowApiError
          errorMapping={{}}
          error={diabetesDataDisplayMode.error.error}
        />
      );
    case 'pending':
      return (
        <Col className={styles.loading}>
          <Loader size="L" />
        </Col>
      );
    case 'success': {
      switch (diabetesDataDisplayMode.data.glycemia_data_display_mode) {
        case 'logbook':
          return (
            <BGMTableCard
              practitioner={state.practitioner}
              patient={state.patient}
              date={state.to}
            />
          );
        case 'continuous':
        case undefined: // If no data, we dispaly the CGM view
          return (
            <GlycemiaProfiles
              patientId={state.patient.id}
              from={state.from}
              to={state.to}
            />
          );
      }
    }
  }
};

const makeStyles = () => ({
  container: css``,
  loading: css`
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  `,
});
