import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { Col } from '@/components/layout/Flex';
import { Loader } from '@/components/loading/Loader';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { CGMThresholdSettings } from '@/pages/patient-settings/CGMThresholdSettings.tsx';
import { TelemonitoringCard } from '@/pages/patient-settings/TelemonitoringCard';
import { AlertSettings } from '@/pages/patient-settings/alert-settings/AlertSettings';
import { Queries } from '@/queries/Queries';

import { BGMThresholdSettings } from './BGMThresholdSettings.tsx';

export default function PatientSettings() {
  const styles = useStyles(makeStyles);
  const patientId = usePatientIdFromURL() ?? '';
  const conditionsQuery = Queries.condition.useCondition(patientId);
  const parameters = Queries.diabetes.useParameters(patientId);

  if (!conditionsQuery.data || !parameters.data) {
    return (
      <Col align="center" justify="center">
        <Loader size="L" />
      </Col>
    );
  }

  const condition = conditionsQuery.data;

  return (
    <div className={styles.container}>
      <TelemonitoringCard patientId={patientId} />
      {parameters.data.type === 'BGM' && (
        <BGMThresholdSettings
          parameters={parameters.data.parameters}
          gestational={condition.type === 'gestational'}
        />
      )}
      <div className={styles.content}>
        {parameters.data.type === 'CGM' && (
          <CGMThresholdSettings parameters={parameters.data.parameters} />
        )}
        <AlertSettings />
      </div>
    </div>
  );
}

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${theme.spacing(12)};
  `,
  content: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${theme.spacing(8)};
  `,
});
