import React from 'react';

import { css, cx } from '@emotion/css';
import { Tab, Tabs, Theme, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PatientListData } from '@/hooks/usePatientsListData.ts';
import { useStyles } from '@/hooks/useStyles';
import { Dot } from '@/uiKit/atoms/Dot';

export type PatientListMenuItems = 'monitored' | 'gestational' | 'all';

export type PatientListMenuProps = {
  selectedItem: PatientListMenuItems;
  selectItem: (item: PatientListMenuItems) => void;
  patientListData: PatientListData;
  className?: string;
};

export const PatientListMenu: React.FC<PatientListMenuProps> = ({
  selectedItem,
  selectItem,
  patientListData,
  className,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  return (
    <Tabs
      value={selectedItem}
      onChange={(_, value) => selectItem(value)}
      variant="scrollable"
      scrollButtons="auto"
      className={cx(styles.container, className)}
    >
      <Tab
        label={t('pages.patients.monitored')}
        icon={
          <TabIcon
            selected={selectedItem === 'monitored'}
            count={patientListData.monitored.length}
          />
        }
        iconPosition="end"
        value="monitored"
      />
      <Tab
        label={t('pages.patients.gestational')}
        icon={
          <TabIcon
            selected={selectedItem === 'gestational'}
            count={patientListData.gestational.length}
          />
        }
        iconPosition="end"
        value="gestational"
      />
      <Tab
        label={t('pages.patients.all')}
        icon={
          <TabIcon
            selected={selectedItem === 'all'}
            count={patientListData.all.length}
          />
        }
        iconPosition="end"
        value="all"
      />
    </Tabs>
  );
};

const TabIcon = ({ selected, count }: { selected: boolean; count: number }) => {
  const theme = useTheme();
  return (
    <Dot
      backgroundColor={
        selected ? theme.palette.common.white : theme.palette.orange.background
      }
      content={<Typography variant="bodySmall">{count}</Typography>}
    />
  );
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  label: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(4)};
    align-items: center;
    flex-wrap: nowrap;
    text-wrap: nowrap;
  `,
});
