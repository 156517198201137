import React, { useMemo } from 'react';

import { css } from '@emotion/css';
import { Typography } from '@mui/material';
import { CellContext } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles.ts';
import {
  PatientListItem,
  TelemonitoringTag,
  telemonitoringTagColorMapping,
  telemonitoringTagTranslationMapping,
} from '@/models/PatientSummaryModel.ts';
import { Tag } from '@/uiKit/tag/Tag.tsx';

export type TelemonitoringColumnProps = CellContext<
  PatientListItem,
  TelemonitoringTag[]
>;

export const TelemonitoringColumn: React.FC<TelemonitoringColumnProps> = ({
  getValue,
}) => {
  const { t } = useTranslation();
  const tagList = getValue();
  const styles = useStyles(makeStyles);

  const tag: TelemonitoringTag | undefined = useMemo(() => {
    if (tagList.includes('active')) {
      return 'active';
    }
    if (tagList.includes('expired')) {
      return 'expired';
    }
    return undefined;
  }, [tagList]);

  return (
    <div className={styles.container}>
      {tag && (
        <Tag
          label={t(telemonitoringTagTranslationMapping(tag))}
          color={telemonitoringTagColorMapping(tag)}
        />
      )}
    </div>
  );
};

export const TelemonitoringHeader: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles(makeHeaderStyles);
  return (
    <div className={styles.container}>
      <Typography>{t('pages.patients.telemonitoring')}</Typography>
    </div>
  );
};

const makeStyles = () => ({
  container: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `,
});

const makeHeaderStyles = () => ({
  container: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `,
});
