import { css, cx } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useStyles';
import { TelemonitoringTags } from '@/models/CarePlanModel';
import {
  telemonitoringTagColorMapping,
  telemonitoringTagTranslationMapping,
} from '@/models/PatientSummaryModel.ts';
import { StyleType } from '@/uiKit/StyleTypes';
import { Tag } from '@/uiKit/tag/Tag';
import { TagList } from '@/uiKit/tag/TagList';

type PatientsTagsListProps = {
  telemonitoringTags: TelemonitoringTags[];
};

export const PatientsTagsList = ({
  className,
  telemonitoringTags,
}: PatientsTagsListProps & StyleType) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  if (!telemonitoringTags.length) {
    return null;
  }

  const tagsSet = new Set(telemonitoringTags);

  // The following rules are UI rules for displaying tags
  if (tagsSet.has('no_glycemia_data')) {
    if (tagsSet.has('expiring_soon')) {
      tagsSet.delete('expiring_soon');
    }
    if (tagsSet.has('expired_recently')) {
      tagsSet.delete('no_glycemia_data');
    } else if (tagsSet.has('expired')) {
      tagsSet.delete('no_glycemia_data');
    }
  }
  if (tagsSet.has('expired_recently')) {
    tagsSet.delete('expired');
  }

  return (
    <TagList className={cx(styles.container, className)}>
      {Array.from(tagsSet).map(tag => {
        return (
          <Tag
            key={tag}
            color={telemonitoringTagColorMapping(tag)}
            label={t(telemonitoringTagTranslationMapping(tag))}
          />
        );
      })}
    </TagList>
  );
};
const makeStyles = () => ({
  container: css`
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 24px; // MUI Chip height
    align-content: flex-start;
  `,
});
