import { Button, Card, CardContent, CardHeader } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { Queries } from '@/queries/Queries';

type OnboardingManagmentProps = {};

export const OnboardingManagment = ({}: OnboardingManagmentProps) => {
  const patientId = usePatientIdFromURL() ?? '';
  const { t } = useTranslation();

  const skipOnboarding = Queries.qa.useSkipOnboarding();
  return (
    <Card>
      <CardHeader title={t('QaTools.onboarding')} />
      <CardContent>
        <Button
          variant="contained"
          onClick={() => skipOnboarding.mutate({ patientId })}
        >
          {t('QaTools.skipOnboarding')}
        </Button>
      </CardContent>
    </Card>
  );
};
