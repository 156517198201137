import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { Loader } from '@/components/loading/Loader';
import { useStyles } from '@/hooks/useStyles';
import { BGMConcentrationType } from '@/models/BGMLogbook';
import {
  BaseGlycemiaParameters,
  GlycemiaParameters,
} from '@/models/DiabetesDataModel.ts';
import { Queries } from '@/queries/Queries.ts';

import { GlycemiaBadge } from './GlycemiaBadge';

export type GlycemiaBadgesProps = {
  patientId: string;
  data: BGMConcentrationType[];
  meal: 'breakfast' | 'lunch' | 'dinner';
  timing: 'before' | 'after';
};

export const GlycemiaBadges: React.FC<GlycemiaBadgesProps> = ({
  patientId,
  data,
  meal,
  timing,
}) => {
  const styles = useStyles(makeStyles);
  const glycemiaParameters = Queries.diabetes.useParameters(patientId);

  return (
    <div className={styles.container}>
      {glycemiaParameters.isSuccess ? (
        data.map((val, i) => (
          <div key={i}>
            <GlycemiaBadge
              glycemiaValue={val}
              thresholds={threshold(meal, timing, glycemiaParameters.data)}
            />
          </div>
        ))
      ) : (
        <Loader size="S" />
      )}
    </div>
  );
};

const threshold = (
  meal: 'breakfast' | 'lunch' | 'dinner',
  timing: 'before' | 'after',
  parameters: GlycemiaParameters,
): BaseGlycemiaParameters => {
  if (parameters.type === 'CGM') {
    return parameters.parameters;
  }
  if (meal === 'breakfast' && timing === 'before') {
    return parameters.parameters.fasting;
  }
  if (timing === 'before') {
    return parameters.parameters.beforeMeal;
  }
  return parameters.parameters.afterMeal;
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,
});
