import React from 'react';

import { css } from '@emotion/css';
import { Card, CardContent, CardHeader } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TextToast } from '@/components/toast/TextToast.tsx';
import { useStyles } from '@/hooks/useStyles';
import {
  CGMGlycemiaParameters,
  EditCGMGlycemiaParameters,
  GlycemiaParametersType,
} from '@/models/DiabetesDataModel.ts';
import { Queries } from '@/queries/Queries.ts';
import { ThresholdSettings } from '@/uiKit/molecules/thresholds/ThresholdSettings.tsx';

export type CGMThresholdSettingsProps = {
  parameters: CGMGlycemiaParameters;
};

export const CGMThresholdSettings: React.FC<CGMThresholdSettingsProps> = ({
  parameters,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const updateThreshold = Queries.diabetes.useEditCGMParameters();

  const onEditThreshold = (threshold: GlycemiaParametersType, value: number) =>
    updateThreshold.mutate(toEditParametersDTO(parameters, threshold, value), {
      onSuccess: () =>
        TextToast.success(
          `${t(`thresholdSettings.${threshold}`)}`,
          `${t('thresholdSettings.thresholdUpdated')}: ${
            parameters[threshold]
          } => ${value}`,
        ),
    });

  return (
    <Card className={styles.container}>
      <CardHeader title={t('thresholdSettings.thresholds')} />
      <CardContent className={styles.content}>
        <ThresholdSettings
          type="all"
          glycemiaParameters={parameters}
          onSubmit={onEditThreshold}
        />
      </CardContent>
    </Card>
  );
};

/**
 * Convert the parameters to the DTO to edit the parameters
 * Set all the parameters to the current value except the one to edit
 */
const toEditParametersDTO = (
  parameters: CGMGlycemiaParameters,
  threshold: GlycemiaParametersType,
  value: number,
): EditCGMGlycemiaParameters => {
  return {
    id: parameters.id,
    severe_hyperglycemia:
      threshold === 'severeHyperglycemia'
        ? value
        : parameters.severeHyperglycemia,
    hyperglycemia:
      threshold === 'hyperglycemia' ? value : parameters.hyperglycemia,
    hypoglycemia:
      threshold === 'hypoglycemia' ? value : parameters.hypoglycemia,
    severe_hypoglycemia:
      threshold === 'severeHypoglycemia'
        ? value
        : parameters.severeHypoglycemia,
  };
};

const makeStyles = () => ({
  container: css`
    height: fit-content;
  `,
  content: css``,
});
